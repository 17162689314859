import Image from 'next/image'
import {Inter} from 'next/font/google'
import {getLclStorage} from '@/utils/localstorage'

const inter = Inter({subsets: ['latin']})

export default function Home() {
  const device = getLclStorage('_syspf', {type: 2})
  let w: any = window
  if (device === 'desktop') {
    w.location = '/login-page'
  }
  return <></>
}
